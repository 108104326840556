















import Vue from 'vue';
import {mapGetters, mapMutations, mapActions} from 'vuex'
import GameHud from '@/components/GameHud.vue'
import GameMap from '@/components/GameMap.vue'
import Fanfair from '@/components/Fanfair.vue'
import Flair from '@/components/Flair.vue'
import Curtain from '@/components/Curtain.vue'
// import PopQuiz from '@/components/PopQuiz.vue'
import MultipleChoice from '@/components/MultipleChoice.vue'

export default Vue.extend({
  name: 'Theater',
  components: {
    GameHud,
    GameMap,
    Fanfair,
    Flair,
    Curtain,
    // PopQuiz,
    MultipleChoice
  },
  data () {
    return {
      curtainActive: true,
      curtainHeading: 'Royal Theater',
      showFanfair: false,
      hudElements: [
        'teamName',
        'progress',
        'timer',
        'leaderboard',
        'current-location',
        'map-button',
        'hints'
      ]
    }
  },
  computed: {
    ...mapGetters([
      'team',
    ]),
    stageCompleted () {
      return (this.team.ready && this.team.completedTheater)
    },
    questions () {
      return this.$store.getters.reviewQuestions
    },
  },
  methods: {
    ...mapActions([
      'timerStart',
      'timerStop',
      'completeStage'
    ]),
    handleHudClick (action: string) {
      if (typeof this[action] === 'function') {
        this[action]()
      }
    },
    showMap () {
      this.$refs.gameMap.show()
    },
    goto (loc: string, heading: string, delay: number) {
      console.log('Maproom.goto')
      this.curtainHeading = heading
      this.curtainActive = true
      window.setTimeout(()=>{
        this.$router.push(loc)
      }, delay)
      this.timerStop()
    },
    onCurtainClick () {
      this.curtainActive = false
    },
    startExercise () {
      this.$refs.quiz.show()
    },
    completeExercise () {
      this.completeStage('Theater')
      this.timerStop()
    }
  },
  mounted () {
    this.$store.dispatch('updateMyTeam', {lastLocation: 'Theater'})
    window.setTimeout(()=>{
      this.curtainActive = false
      if (this.stageCompleted) this.$refs.fanfair.show()
      else {
        if (this.team.seenFindLaptopHint<2) this.$refs.flair.show()
        this.timerStart('Theater')
      }
    }, 500)
  },
  watch: {
    stageCompleted () {
      if (this.stageCompleted) {
        this.$refs.quiz.hide()
        this.$refs.fanfair.show()
        window.setTimeout(this.showMap, 5000)
      } else {
        this.$refs.fanfair.hide()
      }
    }
  }
});
